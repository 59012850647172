require('babel-polyfill');
require('picturefill');
const smoothscroll = require('smoothscroll-polyfill');
const objectFitImages = require('object-fit-images');

(() => {
  const images = [...document.querySelectorAll('.image-fit')];

  smoothscroll.polyfill();
  objectFitImages(images);
})();
